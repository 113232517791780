export default class SitePagePartial {
    readonly list: NodeListOf<HTMLElement>;
    
    constructor() {
        this.list = document.querySelectorAll('.site-page-partial__content');
        this.init();
    }

    private init(): void {
        if (this.list.length > 0) {
            this.list.forEach(item => {
                item.addEventListener('click', () => {
                    this.makeClickable(item);
                });
            });
        }
    }
    
    private makeClickable(element: HTMLElement): void {
        let link = element.querySelector('.site-page-partial__link') as HTMLAnchorElement;
        let linkUrl = link.getAttribute('href') as string;
        
        window.location.href = linkUrl;
    }
}

new SitePagePartial();